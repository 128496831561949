<template>
  <q-padder vertical="xs" horizontal="xs">
    <q-text class="article-section">
      <q-text class="article-section__header">
        <q-text ink="label" variant="ui-small" bold>
          {{ content.QoalaOnlineHomepage.importantInformationLabel }}
        </q-text>
        <q-text
          :title="content.QoalaOnlineHomepage.seeAllLabel"
          ink="primary"
          variant="ui-tiny"
          right
          bold
          style="cursor: pointer"
          @click.native="goToListArticle"
        >
          {{ content.QoalaOnlineHomepage.seeAllLabel }}
        </q-text>
      </q-text>
    </q-text>
  </q-padder>
</template>

<script>
import { Padder as QPadder, Text as QText } from '@qoala/ui';

export default {
  components: {
    QPadder,
    QText,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    countryCode() {
      return this.$store.getters['core/getCountryCode'];
    },
  },
  methods: {
    goToListArticle() {
      const countryPath = {
        ID: 'id',
        MY: 'my',
      };
      const blogUrl = `https://www.qoala.app/${
        countryPath[this.countryCode] ? countryPath[this.countryCode] : 'id'
      }/blog/`;
      this.$helper.openNewTab(blogUrl, '_blank', 'noopener');
    },
  },
};
</script>
<style lang="scss" scoped>
.article-section {
  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
}
</style>
